body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.user-h2 {
    font-weight: 700;
    font-size: 2.25rem;
    margin: 51px 337px 0 0;
    line-height: 1.1;
    word-wrap: break-word;
    position: relative;
    font-family: Roboto,sans-serif;
    color: whitesmoke;
}
.user-p {
    font-size: 1.125rem;
    margin: 28px 406px 0 0;
    word-wrap: break-word;
    position: relative;
    font-family: Montserrat, sans-serif
}
.user-ul {
    font-size: 1.125rem;
    margin: 12px 406px 0 0;
    font-family: Montserrat, sans-serif;
    word-wrap: break-word;
    position: relative;
}
.navBar {
    background-color: #274e74;
}

.navbar-collapse {
    z-index: 999 !important;
}
.navFooter {
    background-color: #274e74;
    padding: 20px;
}
.navLink {
    color: whitesmoke;
}
.navLink:focus {
    color: #f15048;
}
.navLink:hover {
    color: #d8e694;
}
.me-2 {
    float: right;
}
.Register,
.Login {
    border-radius: 10px;
    margin: 4em auto;
    padding: 1em;
    text-align: left;
    max-width: 20%;
    background-color: #f2f2f2;
    box-shadow: 1px 2px 5px grey;
  }

  .form {
    padding: 1em;
  }

  label {
    font-weight: 500;
  }

  .form-label {
      margin-bottom: 0.2em !important;
  }

.mainContainer {
    background-color: #274e74;
}

.imageHolder{
    min-height: 522px;
    position: relative;
    margin: 0 auto;
}

.homeImage {
    width: 798px;
    height: 522px;
    margin: -45px auto 0 -31px;
    object-fit: contain;
    background-size: contain;
    overflow: hidden;
    display: block;
    vertical-align: middle;
    position: relative;
    box-sizing: border-box;
    overflow-clip-margin: content-box;
}
.homeTextBlock {
    color: #ffffff;
    background-color: #37749b;
    margin-left: -15px;
    padding: 35px 35px 35px 35px;
    margin-right: -11px;
}
.baseTextBlock{
    color: #ffffff;
    background-color: #37749b;
    min-height: 100vh;
    padding: 25px 45px 5px 45px;
}
.holderDiv{
    display: flex;
    min-height: 100vh;
    flex-wrap: nowrap;
    flex-direction: column;
    background-color: #37749bq;
}
.submitBtn {
    background-color: #274e74;
    border-color: #274e74;
}
.linkBtn {
    color: #bbc77f;
}
.linkBtn:hover {
    color: white;
}

.submitBtn:hover {
    background-color: #37749b;
    border-color: #37749b;
}
.submitBtnHome {
    margin-top: 20px;
    background-color: #bbc77f;
    border-color: #bbc77f;
}
.submitBtnHome:hover {
    background-color: #bbc77f;
    border-color: #bbc77f;
}
.homeHeadText {
    line-height: 1;
    font-size: 2.25rem;
    font-weight: 700;
    letter-spacing: 1px;
    margin: -269px 0 0 auto;
    padding: 0;
    word-wrap: break-word;
    position: relative;
    text-align: right;
    color: whitesmoke;
    font-family: Roboto,sans-serif;
}
.logOutBtn {
    float: right;
}

@media (max-width: 1199px) {
    .homeHeadText {
        font-size: 1.875rem;
        width: auto;
        margin-top: -241px;
    }
    .homeImage {
        width: 680px;
        height: 446px;
        margin-top: -57px;
        margin-left: -29px;
    }
    .Register,
    .Login {
        width: 80%;
        max-width: 30%;
    }
}

@media (max-width: 991px) {
    .user-p {
        font-size: 1rem;
    }
    .user-ul {
        font-size: 1rem;
        margin: 12px 12px 0 0;
    }
    .user-h2 {
        margin-right: 0;
    }
    .user-p {
        width: auto;
        margin-right: 0;
    }
    .homeHeadText {
        font-size: 2.25rem;
        margin-top: -284px;
    }
    .homeImage {
        width: 680px;
        height: 446px;
        margin-top: -57px;
        margin-left: -29px;
    }
    .imageHolder{
        min-height: 452px;
    }
    .Register,
    .Login {
        width: 70%;
        max-width: 60%;
    }
}

@media (max-width: 767px) {
    .user-h2 {
        width: auto;
    }
    .user-p {
        margin-top: 25px;
    }
    .homeHeadText {
        font-size: 1.875rem;
        margin-top: -280px;
        margin-right: -11px;
    }
    .homeImage {
        width: 540px;
    }
    .imageHolder{
        min-height: 422px;
    }
    .Register,
    .Login {
        width: 80%;
        max-width: 70%;
    }
}

@media (max-width: 575px) {
    .user-h2{
        font-size: 1.5rem;
        margin-top: 25px;
    }
    .homeHeadText {
        margin-top: -335px;
        margin-right: 0;
        font-size: 1.875rem;
    }
    .homeImage {
        width: 826px;
        height: 574px;
        margin-top: -43px;
        margin-left: -525px;
    }
    .imageHolder {
        min-height: 552px;
    }
    .logoBig {
        display: none;
    }
    .Register,
    .Login {
        width: 80%;
        max-width: 70%;
    }
}

@media (min-width: 575px) {
    .logoSmall {
        display: none;
    }
}



.navbar-toggler {
    position: relative;
    transition: .5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 0;
    position: relative;
    z-index: 1;
}

.navbar-toggler span {
    margin: 0;
    padding: 0;
}

.toggler-icon {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #d3531a;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.userSwitch{

}
.accordion-button::after {
    width: 2em;
    margin-left: auto;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}
.accordion-button:not(.collapsed)::after {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}
.App{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}
.videoHolder {
    color: #ffffff;
    background-color: #37749b;
    overflow: hidden;
    padding-bottom: 56.25%;
    margin: 35px 35px 35px 15px;
    position: relative;
    height: 0;
}
.videoHolder iframe {
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}
.holderCol {
    color: #ffffff;
    background-color: #37749b !important;
}

.btn-success {
    --bs-btn-bg: #1878a2;
    --bs-btn-border-color: #1878a2;
    --bs-btn-hover-bg: #274e74;
    --bs-btn-active-bg: #1878a2;
    --bs-btn-active-border-color: #1878a2;
}

.btn-danger {
    --bs-btn-color: #4f4f4f;
    --bs-btn-bg: #d9e694;
    --bs-btn-border-color: #d9e694;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #bac57e;
    --bs-btn-hover-border-color: #bac57e;
    --bs-btn-active-color: #4f4f4f;
    --bs-btn-active-bg: #d9e694;
    --bs-btn-active-border-color: #d9e694;
}

.accordion-button:not(.collapsed) {
    background-color: #d9e694 !important;
    color: #4f4f4f !important;
}

.accordion-button:not(.collapsed)::after {
    background-color: #274e74 !important;
    border-color: #274e74 !important;
}

#enableDisableBtn{
    float: right;
}

#bugSubmitModal{
    float: right;
}

#syncProgressBar{
    float: left;
}